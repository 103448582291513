import api from '../api';

export default {

  list() {
    return api().get('/api/stores');
  },
  get(params) {
    return api().get('/api/stores/details', { params });
  },
  create(params) {
    const apiForMultipart = api('multipart/form-data');
    const formData = new FormData();
    Object.entries(params).forEach((entry) => {
      const [key, value] = entry;
      formData.append(key, value);
    });
    return apiForMultipart.post('/api/stores/new', formData);
  },
  put(params) {
    return api().post('/api/stores/edit', params);
  },
  set(params) {
    return api().post('/api/stores/set', params);
  },
  delete(params) {
    return api().get('/api/stores/delete', { params });
  },

  departments(params) {
    return api().get('/api/stores/departments', { params });
  },

  monthAvailabilities(params, signal = null) {
    return api().get('/api/stores/availabilities', { signal, params });
  },
  departmentAvailabilities(params, signal = null) {
    return api().get('/api/stores/departments/availabilities', { signal, params });
  },
  departmentAvailableTimes(params, signal = null) {
    return api().get('/api/stores/departments/available-times', { signal, params });
  },

  contractualClauses(params) {
    return api().get('/api/stores/contractual-clauses', { params });
  },
  consents(params) {
    return api().get('/api/stores/consents', { params });
  },
  setHours(params) {
    return api().post('/api/stores/hours', params);
  },
  uploadLogo(params) {
    const apiForMultipart = api('multipart/form-data');
    return apiForMultipart.post('/api/stores/upload-logo', params);
  },
  primaNotaPDF(params) {
    return api().get('/api/stores/prima-nota', { params, responseType: 'blob' });
  },

  clientAttributes(params) {
    return api().get('/api/stores/client-attributes', { params });
  },

  getHolidaysDate(params) {
    return api().get('/api/stores/holidays', { params });
  },

  getEmployeeHolidaysDate(params) {
    return api().get('/api/stores/employee-holidays', { params });
  },

  getActiveHolidays(params) {
    return api().get('/api/stores/active-holidays', { params });
  },

  setActiveHolidays(params) {
    return api().post('/api/stores/active-holidays', params);
  },

  setLimitedIp(params) {
    return api().post('/api/stores/limited-ip', params);
  },
};
