import repository from '@/api/repository';
import {isEmpty} from "lodash";
const getOpenAppointment = () => {
  const val = localStorage.getItem('appointment');
  return val ? JSON.parse(val) : null;
};

export default {
  state: () => ({
    store: sessionStorage.store ? JSON.parse(sessionStorage.getItem('store')) : null,
    sessionUser: sessionStorage.user ? JSON.parse(sessionStorage.getItem('user')) : null,
    changeStore: false,
    openAppointment: getOpenAppointment(),
    appointments: [],
    wizardAppointmentData: null,
    appointmentFilters: {
      department_id: null,
      employee_id: null,
      appointment_type_id: null,
    },
    contractsFilters: {},
    contractsFiltersActive: [],
  }),
  getters: {
    storeId: (state) => state.store?.id,
    store: (state) => state.store,
    changeStore: (state) => state.changeStore,

    activeNotifications: (state) => state.store?.notification_active && !isEmpty(state.store?.whatsapp_number),

    openAppointment: (state) => state.openAppointment,
    appointments: (state) => state.appointments,
    appointmentFilters: (state) => state.appointmentFilters,
    wizardAppointmentData: (state) => state.wizardAppointmentData,

    contractsFilters: (state) => state.contractsFilters,
    contractsFiltersActive: (state) => state.contractsFiltersActive,
  },
  mutations: {
    SET_STORE(state, store) {
      state.store = store;
      if (store) {
        sessionStorage.store = JSON.stringify(store);
      } else {
        sessionStorage.removeItem('store');
      }
    },
    CHANGE_STORE(state, value) {
      state.changeStore = value;
    },
    OPEN_APPOINTMENT(state, value) {
      state.openAppointment = value;
      localStorage.setItem('appointment', JSON.stringify(value));
    },
    SET_APPOINTMENTS(state, appointments) {
      state.appointments = appointments;
    },
    SET_APPOINTMENT_FILTERS(state, value) {
      state.appointmentFilters = value;
    },
    SET_WIZARD_APPOINTMENT_DATA(state, value) {
      state.wizardAppointmentData = value;
    },

    /** CONTRACTS **/
    SET_CONTRACTS_FILTERS(state, value) {
      state.contractsFilters = value;
    },
    SET_CONTRACTS_FILTERS_ACTIVE(state, value) {
      state.contractsFiltersActive = value;
    },
  },
  actions: {
    /* Store */
    async getStore({ commit, dispatch }, storeId) {
      const { data } = await repository.store.get({id: storeId});
      commit('SET_STORE', data.data)
      dispatch('getEmployees');
    },
    async refreshStore({commit, getters}) {
      const storeId = getters.storeId;
      const {data} = await repository.store.get({id:storeId});
      commit('SET_STORE', data.data);
    },

    async postStore({ commit }, store) {
      const { data } = await repository.store.create(store);
      commit('SET_STORE', data.data);
    },
    async setStore({ commit }, store) {
      const { data } = await repository.store.set(store);
      commit('SET_STORE', data.data);
    },
    async updateStore({ commit }, store) {
      commit('SET_STORE', store);
    },

    /* Appointment */
    async openAppointment({ commit }, appointment) {
      commit('OPEN_APPOINTMENT', appointment);
    },
    async setAppointments({ commit, getters }) {
      commit('UPDATE_LOADING', true);
      const params = getters.appointmentFilters;
      if(params.month || params.start_to) {
        const { data } = await repository.appointment.list(params);
        commit('SET_APPOINTMENTS', data);
      }
      commit('UPDATE_LOADING', false);
    },
    async closeAppointment({ state, commit, dispatch }) {
      if (state.openAppointment) {
        const appointmentId = state.openAppointment.id;
        const storeId = state.openAppointment.store_id;
        await repository.appointment.close({ store_id: storeId, id: appointmentId });
        await dispatch('setAppointments', { store_id: storeId });
      }
      commit('OPEN_APPOINTMENT', null);
    },
    async suspendAppointment({ commit }) {
      commit('OPEN_APPOINTMENT', null);
    },
    async setWizardAppointmentData({ commit }, value) {
      const defaultData = {
        show: false,
        datetime: null,
        appointmentId: null,
        contractId: null,
      };
      let newData = null;
      if (value) {
        newData = {
          ...defaultData,
          ...value,
        };
      }
      commit('SET_WIZARD_APPOINTMENT_DATA', newData);
    },
    async setAppointmentFilters({ commit }, value) {
      commit('SET_APPOINTMENT_FILTERS', value);
    },

    /** CONTRACTS **/
    async setContractsFilters({ commit }, value) {
      commit('SET_CONTRACTS_FILTERS', value);
    },
    async setContractsFiltersActive({ commit }, value) {
      commit('SET_CONTRACTS_FILTERS_ACTIVE', value);
    },


    /** EMPLOYEE **/
    async getEmployees({ commit }) {
      const {data} = await repository.employee.list();
      data.sort((a, b) => {
        const nameA = a.full_name.toUpperCase();
        const nameB = b.full_name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      commit('SET_EMPLOYEES', data);
    },
  },
};
